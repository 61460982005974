import { graphql, useStaticQuery } from 'gatsby';

import BlogIndexQueryData from 'interfaces/BlogIndexQueryData';

const useBlogIndexQuery = () => {
  const { allMdx }: BlogIndexQueryData = useStaticQuery(graphql`
    query BLOG_INDEX_QUERY {
      allMdx(
        filter: { fileAbsolutePath: { regex : "\/content\/blog/" } }
        sort: { fields: [frontmatter___publishedAt], order: DESC }
      ) {
        nodes {
          id
          frontmatter {
            title
            publishedAt
          }
          timeToRead
          slug
        }
      }
    }
  `);

  return { allMdx };
};

export default useBlogIndexQuery;