import React from 'react';

import useBlogIndexQuery from 'hooks/useBlogIndexQuery';

import * as Styled from './style';
import { formatDate } from '../utils';

const BlogIndex = () => {
  const { allMdx: { nodes: blogPostPreviews } } = useBlogIndexQuery();

  return(
    <Styled.BlogIndex>
      {blogPostPreviews.map(({ 
        id, 
        frontmatter: { title, publishedAt },
        timeToRead,
        slug,
      }, idx) => (
        <Styled.BlogPreview key={id}>
          <Styled.BlogLink to={`/blog/${slug}`}>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Caption>
              Entry {`${ blogPostPreviews.length - idx}`.padStart(2, '0')} ✸{' '}
              {formatDate(publishedAt)} ✸{' '}
              {`${timeToRead}`.padStart(2, '0')} min read
            </Styled.Caption>
          </Styled.BlogLink>
        </Styled.BlogPreview>
      ))}
    </Styled.BlogIndex>
  );
};

export default BlogIndex;
